import React from "react";
import { Link } from "gatsby-theme-material-ui";
import Typography from '@material-ui/core/Typography';
import Box  from "@material-ui/core/Box";
import styled from 'styled-components';
import {device, deviceMax} from "../helpers/devicesizes";
import Grid from '@material-ui/core/Grid';
import FacebookSVG from '../../svg-assets/fb.svg';
import TwitterSVG from '../../svg-assets/twitter.svg';
import LinkedinSVG from '../../svg-assets/linkedin.svg';

const FooterBox = styled(Box)`
  @media ${device.tablet} {
    height: 320px;
    padding: 36px 64px 0 64px;
  }
  @media ${deviceMax.tablet} {
    height: 320px;
    padding: 27px 24px 0 24px;
  }
`;

const FooterLink = styled(Link)`
  display: block;
  padding-bottom: 5px;
`;

const CopyrightBox = styled(Box)`
  padding-top: 35px;
`;

const FacebookIcon = styled(FacebookSVG)`
  height: 32px;
  width: 32px;
  margin: 0 4px;
`;

const TwitterIcon = styled(TwitterSVG)`
  height: 32px;
  width: 32px;
  margin: 0 4px;
`;

const LinkedInIcon = styled(LinkedinSVG)`
  height: 32px;
  width: 32px;
  margin: 0 4px;
`;

const SomeIconContainer = styled.div`
@media ${device.tablet} {
  display:inline-block;
}
@media ${deviceMax.tablet} {
  display:block;
}
`;

export default function FooterMatkustajapalvelut({baseurl}) {
    return (
      <FooterBox bgcolor="primary.main">
        <Grid container spacing={0}>
          <Grid item xs={11} sm={9}>
            <Grid container spacing={0}>
              <Grid item xs={12} sm={9}>
                  <FooterLink variant="body2" color="secondary" to={baseurl ? baseurl+"/matkahuolto-kokemuksia": "/matkahuolto-kokemuksia"}>Lue muiden kokemuksia</FooterLink>
                  <FooterLink variant="body2" color="secondary" to="https://www.matkahuolto.fi/blog/katkesiko-ketju">Lisätietoa Katkesiko ketju -kampanjasta</FooterLink>
                  <FooterLink variant="body2" color="secondary" to="/ei-haukku-haavaa-tee">Ei haukku haavaa tee -kampanja</FooterLink>
                  <FooterLink variant="body2" color="secondary" to="https://medium.com/@jaakkotimonen/mik%C3%A4-kumman-nonono-fi-a28eebd59d6f">Tietoa meistä</FooterLink>
                  <FooterLink variant="body2" color="secondary" to="https://medium.com/nonono-fi/12-pointtia-muille-kuin-sinulle-de2f363c682f">Yrityksille</FooterLink>
                  <FooterLink variant="body2" color="secondary" to="mailto:jaakko@nonono.com">Ota yhteyttä</FooterLink>
                  <FooterLink variant="body2" color="secondary" to="https://docs.google.com/document/d/1wKgp4Z0mRihJ39EJQNBExxiwG0mF-T5NjJmxiWKZpZI/edit">Käyttöehdot</FooterLink>
                  <FooterLink variant="body2" color="secondary" to="https://docs.google.com/document/d/1HLSwNapAwYc2VvM3r9HbyjJKevBxy-i6qXtViV78wsA/edit">Tietosuojalauseke</FooterLink>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={1} sm={3}>
            <SomeIconContainer>
            <a href="https://www.facebook.com/nonono.suomi" aria-label="Facebook" alt="Facebook"><FacebookIcon /></a>
            </SomeIconContainer>
            <SomeIconContainer>
            <a href="https://twitter.com/nonono_fi" aria-label="Twitter" alt="Twitter"><TwitterIcon /></a>
            </SomeIconContainer>
            <SomeIconContainer>
            <a href="https://www.linkedin.com/company/nonono/" aria-label="LinkedIn" alt="LinkedIn"><LinkedInIcon /></a>
            </SomeIconContainer>
          </Grid>
          <Grid item xs={12}>
            <CopyrightBox display="flex" alignContent="flex-end" justifyContent="center">
              <Typography variant="caption" color="secondary">Copyright &copy; 2021 No No No</Typography>
            </CopyrightBox>
          </Grid>
        </Grid>
      </FooterBox>
    )
  }