import React from "react"
import LayoutMatkustajapalvelut from '../components/layout-matkustajapalvelut';
import { graphql } from "gatsby";
import BrandProfile from "../components/brandprofile";

const Matkustajapalvelut = ({data}) => {
    return <LayoutMatkustajapalvelut children={<BrandProfile slug={"matkahuolto-matkustajapalvelut"} serverUrl={data.site.siteMetadata.serverUrl} />} />
};

export default Matkustajapalvelut;

export const query = graphql`
  query MHQuery {
    site {
      siteMetadata {
        siteUrl
        serverUrl
        title
        description
      }
    }
  }
`